import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Flex, Input, message, Tag, theme, Tooltip } from "antd";

const tagInputStyle: React.CSSProperties = {
  width: 64,
  height: 22,
  marginInlineEnd: 8,
  verticalAlign: "top",
};

const EmailUpdateForm = (props: any) => {
  const { token } = theme.useToken();
  const [tags, setTags] = useState<string[]>(["tabrez@kgrp.in"]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (props.emailData) setTags(props.emailData);
  }, [props.emailData]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
    props.onChange && props.onChange(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    console.log(inputValue,"inputValue")
  };

  // const handleInputConfirm = () => {
  //   if (!isValidEmail(inputValue)) {
  //     messageApi.error({
  //       type: "error",
  //       content: "Invalid email",
  //     });
  //     return;
  //   } else {
  //     if (inputValue && !tags.includes(inputValue)) {
  //       setTags([...tags, inputValue]);
  //       props.onChange && props.onChange([...tags, inputValue]);
  //     }
  //     setInputVisible(false);
  //     setInputValue("");
  //   }
  // };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  // const handleEditInputConfirm = () => {
  //   const newTags = [...tags];
  //   if (!isValidEmail(editInputValue)) {
  //     messageApi.error({
  //       type: "error",
  //       content: "Invalid email",
  //     });
  //     return;
  //   }
  //   newTags[editInputIndex] = editInputValue;
  //   setTags(newTags);
  //   props.onChange && props.onChange(newTags);
  //   setEditInputIndex(-1);
  //   setEditInputValue("");
  // };
  const handleInputConfirm = () => {
    if (!isValidInput(inputValue)) {
      messageApi.error({
        type: "error",
        content: `Invalid ${props.type}`,
      });
      return;
    } else {
      if (inputValue && !tags.includes(inputValue)) {
        const newTags = [...tags, inputValue];
        setTags(newTags);
        props.onChange && props.onChange(newTags);
      }
      setInputVisible(false);
      setInputValue("");
    }
  };

  // const handleEditInputConfirm = () => {
  //   const newTags = [...tags];
  //   if (!isValidInput(editInputValue)) {
  //     messageApi.error({
  //       type: "error",
  //       content: `Invalid ${props.type}`,
  //     });
  //     return;
  //   }
  //   newTags[editInputIndex] = editInputValue;
  //   setTags(newTags);
  //   props.onChange && props.onChange(newTags);
  //   setEditInputIndex(-1);
  //   setEditInputValue("");
  // };
  const handleEditInputConfirm = () => {
    const trimmedValue = editInputValue.trim();
  
    // Allow clearing the input
    if (trimmedValue === "") {
      const newTags = [...tags];
      newTags.splice(editInputIndex, 1); // Remove the tag being edited
      setTags(newTags);
      props.onChange && props.onChange(newTags);
  
      setEditInputIndex(-1);
      setEditInputValue(""); // Reset editing state
      return;
    }
  
    // Validate the trimmed input
    if (!isValidInput(trimmedValue)) {
      messageApi.error({
        type: "error",
        content: `Invalid ${props.type}`,
      });
      return;
    }
  
    const newTags = [...tags];
    newTags[editInputIndex] = trimmedValue;
    setTags(newTags);
    props.onChange && props.onChange(newTags);
  
    setEditInputIndex(-1);
    setEditInputValue(""); // Reset editing state
  };
  
  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  function isValidEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  }

  const isValidInput = (value: string) => {
    if (props.type === "email") {
      return isValidEmail(value);
    } else if (props.type === "phone") {
         // Matches +91 followed by 10 digits or just 10 digits
    return /^(?:\+91)?\d{10}$/.test(value.trim());
    }
    return false;
  };

  return (
    <Flex gap="4px 0" wrap>
      {tags.map<React.ReactNode>((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              size="small"
              style={{ ...tagInputStyle, width: 240 }}
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag
            key={tag}
            // closable={index !== 0}
            style={{ userSelect: "none" }}
            onClose={() => handleClose(tag)}
          >
            <span
              onDoubleClick={(e) => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible ? (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          style={tagInputStyle}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : (
        <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
          {/* Add Email */}
          Add {props.type === "email" ? "Email" : "Phone Number"}
        </Tag>
      )}

      {contextHolder}
    </Flex>
  );
};

export default EmailUpdateForm;
