import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./SearchInput.scss";
export default function SearchInput(props: any) {
  return (
    <Input
      placeholder={props.placeholder || "Search member"}
      prefix={<SearchOutlined className="site-form-item-icon" />}
      style={{
        borderRadius: props.rounded ? 24 : 6,
        width: "100%",
        height: props.height || "auto",
      }}
      size="small"
      className="SearchInput"
      onChange={(e: any) => props.onSearch(e.target.value)}
    />
  );
}
