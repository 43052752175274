import { Button, Form, Input, message } from "antd";
import Typography from "app/shared/Typography";
import EmailUpdateForm from "../EmailUpdate";
import { useEffect, useState } from "react";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_UPDATE_HOTEL_DETAIL } from "../../hotel.booking.constants";

export default function HotelDataUpdate(props: any) {
  const [hotelName, setHotelName] = useState("");
  const [hotelCode, setHotelCode] = useState("");
  const [hotelGstin, setHotelGstin] = useState("");

  const [hotelEmails, setHotelEmails] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [hotelNumbers, setHotelNumbers] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  
  console.log("booking data:'", props.bookingData);
  useEffect(() => {
    if (props.bookingData) {
      setHotelName(props.bookingData?.hotel_details?.hotel_name);
      setHotelCode(
        props.bookingData?.hotel_details?.hotel_code ||
          props.bookingData.hotel_code
      );
      setHotelGstin(props.bookingData?.hotel_details?.hotel_gstin);
      setHotelNumbers(props.bookingData?.hotel_details?.hotel_phone);

      if (props.type === "CC") {
        setHotelEmails(props.bookingData?.cc_emails || []);
      } else {
        setHotelEmails(props.bookingData?.hotel_details?.hotel_emails);
      }
    }
  }, [props.bookingData]);

  console.log("props.booking", hotelCode);

  const handleSubmitHotelInfo = async () => {
    setLoading(true);
    const payload = {
      hotel_code: hotelCode,
      hotel_name: hotelName || "",
      hotel_emails: hotelEmails || [],
      hotel_gstin: hotelGstin,
      hotel_phone: hotelNumbers,
    };
    console.log("submit payload", payload);
    const response = await apiPost(API_ENDPOINT_UPDATE_HOTEL_DETAIL, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Hotel details updated",
      });
      setHotelEmails([]);
      setHotelGstin("");
      setHotelNumbers([]);
      props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not updated hotel detail",
      });
    }
    setLoading(false);
  };

  const handleSubmitCCMails = async () => {
    setLoading(true);
    const payload = {
      cc_emails: hotelEmails || [],
      recordLocator: props.bookingData.recordLocator,
    };
    const response = await apiPost(API_ENDPOINT_UPDATE_HOTEL_DETAIL, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Hotel details updated",
      });
      props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not updated hotel detail",
      });
    }
    setLoading(false);
  };
  

  return (
    <div className="HotelUpdateForm" style={{ marginTop: 32 }}>
      <Form
        name="basic"
        initialValues={{
          remember: true,
          hotel_name: hotelName,
          hotel_code: hotelCode,
          hotel_gstin: hotelGstin,
        }}
        fields={[
          {
            name: ["hotel_code"],

            value: hotelCode,
          },
          {
            name: ["hotel_name"],

            value: hotelName,
          },
          {
            name: ["hotel_gstin"],

            value: hotelGstin,
          },
        ]}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {props.type === "HOTEL" ? (
          <>
            <Form.Item
              name="hotel_name"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                placeholder="Hotel Name"
                defaultValue={hotelName}
                onChange={(e: any) => setHotelName(e.target.value)}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="hotel_code"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
              style={{ marginTop: 12 }}
            >
              <Input
                placeholder="Hotel Code"
                defaultValue={hotelCode}
                disabled
              />
            </Form.Item>

            <Form.Item name="hotel_gstin" style={{ marginTop: 12 }}>
              <Input
                placeholder="Hotel GSTIN"
                defaultValue={hotelGstin}
                onChange={(e: any) => setHotelGstin(e.target.value)}
              />
            </Form.Item>
          </>
        ) : null}

        <div style={{ marginTop: 24, marginBottom: 24 }}>
          <Typography variant="xs" style={{ marginBottom: 6 }}>
            {props.type === "CC" ? "CC" : ""} Emails
          </Typography>
          <EmailUpdateForm
            type="email"
            emailData={hotelEmails}
            onChange={(data: any) => setHotelEmails(data)}
          />
        </div>
        {props.type === "HOTEL" ? (
          <div style={{ marginTop: 24, marginBottom: 24 }}>
            <Typography variant="xs" style={{ marginBottom: 6 }}>
              {props.type === "CC" ? "CC" : ""} Phone Numbers
            </Typography>
            <EmailUpdateForm
              type="phone"
              emailData={hotelNumbers}
              onChange={
               
                (data: any) => setHotelNumbers(data)}
            />
          </div>
        ) : null}

        <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            onClick={
              props.type === "CC" ? handleSubmitCCMails : handleSubmitHotelInfo
            }
            loading={isLoading}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      {contextHolder}
    </div>
  );
}
